<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" @change="(event) => updateMachTypeDropdown(event)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="divisionModel" :items="divisionLists" default="" item-value="class" item-text="descr" label="Select Division" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machTypeModel" :items="machTypeLists" default="" item-value="mach_type" item-text="descr" label="Select Mach. Type" @change="(event) => updateMachIDDropdown(event)" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machIDModel" :items="machIDLists" default="" item-value="mach_id" item-text="descr" label="Select Mach. ID" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="mr-4" color="primary" elevation="2" small rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph1">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 423px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph2">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer2" style="height: 423px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="modal fade modal-sm" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
                <div class="modal-dialog" style="max-width: 50%;">
                    <div class="modal-content bgCustom3">
                        <div class="modal-body">
                            <div id="chartContainer3" style="height: 423px; width: 100%;"></div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'Production Performance',
                disabled: false,
                href: '#',
                },
                {
                text: 'Downtime Analysis',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            divisionModel: '',
            divisionLists: [],
            machTypeModel: '',
            machTypeLists: [],
            machIDModel: '',
            machIDLists: [],
            dateFrom: '',
            modalDateFrom: false,
            dateTo: '',
            modalDateTo: false
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            CanvasJS.addColorSet("colorSet1",[
                "#277da1",
                "#f3722c",
                "#f8961e",
                "#f9844a",
                "#f9c74f",
                "#90be6d",
                "#43aa8b",
                "#4d908e",
                "#577590",
                "#f94144"
            ])

            CanvasJS.addColorSet("colorSet2",[
                "#54478c",
                "#2c699a",
                "#048ba8",
                "#0db39e",
                "#16db93",
                "#83e377",
                "#b9e769",
                "#efea5a",
                "#f1c453",
                "#f29e4c"
            ])

            document.querySelector(".cardGraph1").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";

            this.dateFrom = this.currentPeriod()
            this.dateTo = this.currentPeriod()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/DowntimeAnalysis`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.divisionLists = res.data.division
                this.machTypeLists = res.data.machType
                this.machIDLists = res.data.machID
                this.$store.dispatch('setOverlay', false)
            })
        },

        async getChartData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph1").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/DowntimeAnalysis/getChartData`, { 

                mill: this.millModel ? this.millModel : '',
                division: this.divisionModel ? this.divisionModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                dateTo: this.dateToFormatted ? this.dateToFormatted : '',

            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.arr_list1.length != 0){
                    document.querySelector(".cardGraph1").style.display = "block";
                    document.querySelector(".cardGraph2").style.display = "none";
                    this.renderChart1(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph1").style.display = "none";
                    document.querySelector(".cardGraph2").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }

            })

        },

        async getChartDataDetails(e){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph2").style.display = "none";

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/DowntimeAnalysis/getChartDataDetails`, { 

                mill: this.millModel ? this.millModel : '',
                division: this.divisionModel ? this.divisionModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                period: e.dataPoint.label,
                fulltime: e.dataPoint.y

            },
                {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                document.querySelector(".cardGraph2").style.display = "block";
                this.renderChart2(res.data.arr_list1, res.data.arr_list2, e.dataPoint.label, e.dataPoint.y)

            })

        },

        async getChartDataDetails2(e){

            this.$store.dispatch('setOverlay', true)

            $('#detailModal').modal('show')

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/DowntimeAnalysis/getChartDataDetails2`, { 

                mill: this.millModel ? this.millModel : '',
                division: this.divisionModel ? this.divisionModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                period: e.dataPoint.period,
                classType: e.dataPoint.label

            },
                {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                console.log(e.dataPoint.period)
                this.renderChart3(res.data.arr_list1, e.dataPoint.label, e.dataPoint.period)

            })

        },

        renderChart1(data1, data2, data3){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                    animationEnabled: true,
                    theme: "light2",
                    title: {
                        text: "Downtime Chart Analysis",
                        fontFamily: "Calibri",
                        fontSize: 20
                    },
                    exportEnabled: false,
                    axisX:{
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        }
                    },
                    axisY: {
                        scaleBreaks: {
                            autoCalculate: true,
                            type: "wavy"
                        },
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "Hourly",
                        labelFormatter: this.addSymbols,
                    },
                    axisY2: {
                        title: "Downtime (%)",
                        suffix: "%",
                        titleFontColor: "#0f4c75",
                        lineColor: "#0f4c75",
                        labelFontColor: "#0f4c75",
                        tickColor: "#0f4c75",
                        includeZero: true
                    },
                    toolTip:{
                        shared:true
                    },
                    legend: {
                        cursor: "pointer",
                        itemclick: this.toggleDataSeries
                    },
                    data: [
                        {
                            type: "column",
                            click: this.getChartDataDetails,
                            name: "Fulltime",
                            showInLegend: true,
                            indexLabel: "{y}",
                            indexLabelPlacement: "outside",
                            indexLabelOrientation: "horizontal",
                            indexLabelFontWeight: "bold",
                            indexLabelFontSize: 12,
                            indexLabelFontFamily: "calibri",
                            color: "#00b7c2",
                            dataPoints: data1
                        },
                        {
                            type: "area",
                            name: "Downtime (Val)",
                            showInLegend: true,
                            indexLabel: "{y}",
                            indexLabelPlacement: "inside",
                            indexLabelOrientation: "horizontal",
                            indexLabelFontWeight: "bold",
                            indexLabelFontSize: 13,
                            indexLabelFontFamily: "calibri",
                            indexLabelFontColor: "#b52b65",
                            color: "#b52b65",
                            dataPoints: data2
                        },
                        {
                            type: "spline",
                            name: "Downtime (%)",
                            axisYType: "secondary",
                            yValueFormatString: "0.##\"%\"",
                            showInLegend: true,
                            indexLabel: "{y}",
                            indexLabelPlacement: "inside",
                            indexLabelOrientation: "horizontal",
                            indexLabelFontWeight: "bold",
                            indexLabelFontSize: 13,
                            indexLabelFontFamily: "calibri",
                            indexLabelFontColor: "#0f4c75",
                            color: "#0f4c75",
                            dataPoints: data3
                        },
                    ]
                })

                chart1.render()

        },

        renderChart2(data1, data2, period, fulltime){

            var chart2 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                theme: "light2",
                title: {
                    text: "Downtime each class for period " + period + " (100% = "+ fulltime +" )",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    }
                },
                axisY: {
                    scaleBreaks: {
                        autoCalculate: true,
                        type: "wavy"
                    },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Hourly (Val)",
                    labelFormatter: this.addSymbols,
                },
                axisY2: {
                    title: "Downtime % (/Fulltime)",
                    suffix: "%",
                    titleFontColor: "#0f4c75",
                    lineColor: "#0f4c75",
                    labelFontColor: "#0f4c75",
                    tickColor: "#0f4c75",
                    includeZero: true
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.getChartDataDetails2,
                        name: "Hourly (Val)",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelOrientation: "horizontal",
                        indexLabelFontWeight: "bold",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#b52b65",
                        dataPoints: data1
                    },
                    {
                        type: "spline",
                        name: "Downtime (%)",
                        axisYType: "secondary",
                        yValueFormatString: "0.##\"%\"",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "horizontal",
                        indexLabelFontWeight: "bold",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        indexLabelFontColor: "#0f4c75",
                        color: "#0f4c75",
                        dataPoints: data2
                    },
                ]
            })

            chart2.render()

        },

        renderChart3(data1, classType, period){

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                colorSet: "colorSet1",
                animationEnabled: true,
                exportEnabled: false,
                theme: "light2",
                title: {
                    text: "Detail of " + classType + " for period " + period,
                    fontFamily: "Calibri",
                    fontSize: 14
                },
                data: [{
                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} {y} #percent%",
                    indexLabelFontSize: 10,
                    dataPoints: data1
                }]
            })

            chart3.render()

        },

        async updateMachTypeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/DowntimeAnalysis/getMachineByMill?mill=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/DowntimeAnalysis/getMachineByMill?mill=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            }
        },

        async updateMachIDDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/DowntimeAnalysis/getMachineByID?mill=${this.millModel}&machType=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machIDLists = []
                    this.machIDLists = res.data.machID
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/DowntimeAnalysis/getMachineByID?mill=all&machType=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machIDLists = []
                    this.machIDLists = res.data.machID
                })
            }
        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        currentPeriod() {
            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>